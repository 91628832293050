<!--定义侧边栏样式-->
<template>
  <div>
<!--    menu中写一个router就可以根据index的数据跳转到相应的vue-->
    <el-menu

        router
        class="el-menu-vertical-demo"
         style="width: 150px;min-height: 90vh;">
          <el-menu-item @click="c1" index="user_main" :style="active1" >首页</el-menu-item>
          <el-menu-item @click="c2" index="user_photo_process" :style="active2">PC端图像识别</el-menu-item>
          <el-menu-item @click="c3" index="user_history" :style="active3">历史记录</el-menu-item>

    </el-menu>
  </div>
</template>

<script>

export default {
  name: "Aside_user",

  data(){
    return{
      active1:'text-align: center;font-size: 15px;color:white;font-weight: bold;background-image: linear-gradient(to right,limegreen ,darkgreen);',
      active2:'text-align: center;font-size: 15px;color: black;font-weight: bold;',
      active3:'text-align: center;font-size: 15px;color: black;font-weight: bold;',


    }
  },
  methods:{

    c1(){


      this.active1="text-align: center;font-size: 15px;color:white;font-weight: bold;background-image: linear-gradient(to right,limegreen ,darkgreen);"
      this.active2="text-align: center;font-size: 15px;color:black;font-weight: bold;"
      this.active3="text-align: center;font-size: 15px;color:black;font-weight: bold;"



    },
    c2(){
      this.active2="text-align: center;font-size: 15px;color:white;font-weight: bold;background-image: linear-gradient(to right,limegreen ,darkgreen);"
      this.active1="text-align: center;font-size: 15px;color:black;font-weight: bold;"
      this.active3="text-align: center;font-size: 15px;color:black;font-weight: bold;"

    },

    c3(){
      this.active3="text-align: center;font-size: 15px;color:white;font-weight: bold;background-image: linear-gradient(to right,limegreen ,darkgreen);"
      this.active1="text-align: center;font-size: 15px;color:black;font-weight: bold;"
      this.active2="text-align: center;font-size: 15px;color:black;font-weight: bold;"

    },





   }
}
</script>

<style >

</style>