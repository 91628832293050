import axios from 'axios'//����axios��
import router from "@/router";
const request = axios.create({
    // baseURL: 'http://localhost:8080',  // ע�⣡�� ������ȫ��ͳһ������ '/api' ǰ׺��Ҳ����˵���нӿڶ������'/api'ǰ׺�ڣ�ҳ������д�ӿڵ�ʱ��Ͳ�Ҫ�� '/api'�ˣ���������2��'/api'������ '/api/api/user'�����ı������мǣ�����
    timeout: 100000
})

// request ������
// ������������ǰ��������һЩ����
// ����ͳһ��token�����������ͳһ����
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    let user = JSON.parse(localStorage.getItem("honey-user") || '{}');
    config.headers['token'] = user.token;  // ��������ͷ
    //ȡ��sessionStorage������û���Ϣ
    //�������Ϊ�գ�����ת����¼����

    return config
}, error => {
    return Promise.reject(error)
});

// response ������
// �����ڽӿ���Ӧ��ͳһ�������
request.interceptors.response.use(
    response => {
        let res = response.data;
        // ����Ƿ��ص��ļ�
        if (response.config.responseType === 'blob') {
            return res
        }
        // ���ݷ���˷��ص��ַ�������
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        return res;
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)


export default request

