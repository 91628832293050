<!--������̨��ҳ�Ŀ�ܽ���-->
<template>
  <!--    ͷ��-->
  <Header/>
  <!--    ����-->
  <div style="display: flex">
    <!--      �����-->
    <Aside_user />
    <!--      ��������-->
    <router-view style="flex: 1"/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Aside from "@/components/Aside";
import Aside_user from "@/components/Aside_user";
export default {
  name: "Layout",
  components:{
    Header,
    Aside_user

  }
}
</script>

<style scoped>

</style>