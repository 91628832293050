<!--定义侧边栏样式-->
<template>
  <div>
<!--    menu中写一个router就可以根据index的数据跳转到相应的vue-->
    <el-menu

        router
        class="el-menu-vertical-demo"
         style="width: 200px;min-height: 90vh;background-image: linear-gradient(to top, #cccbcb ,#cccbcb);overflow: hidden">
          <el-menu-item @click="c1" index="admin_main" :style="active1" >首页</el-menu-item>
          <el-menu-item @click="c2" index="photo_process" :style="active2" >PC端图像识别</el-menu-item>

          <el-menu-item @click="c3" index="user_manage" :style="active3">用户管理</el-menu-item>
          <el-menu-item  @click="c4" index="img_manage" :style="active4">图像管理</el-menu-item>
          <el-menu-item  @click="c5" index="model_manage" :style="active5">模型管理</el-menu-item>

    </el-menu>
  </div>
</template>

<script>

export default {
  name: "Aside",

  data(){
    return{
      active1:'text-align: center;font-size: 15px;color:white;font-weight: bold;background-image: linear-gradient(to right,limegreen ,darkgreen);',
      active2:'text-align: center;font-size: 15px;color: black;font-weight: bold',
      active3:'text-align: center;font-size: 15px;color: black;font-weight: bold',
      active4:'text-align: center;font-size: 15px;color: black;font-weight: bold',
      active5:'text-align: center;font-size: 15px;color: black;font-weight: bold',




    }
  },
  methods:{

    c1(){


      this.active1="text-align: center;font-size: 15px;color:white;font-weight: bold;background-image: linear-gradient(to right,limegreen ,darkgreen);"
      this.active4="text-align: center;font-size: 15px;color:black;font-weight: bold;background-color:#cccbcb"
      this.active3="text-align: center;font-size: 15px;color:black;font-weight: bold;background-color:#cccbcb"
      this.active2="text-align: center;font-size: 15px;color:black;font-weight: bold;background-color:#cccbcb"
      this.active5="text-align: center;font-size: 15px;color:black;font-weight: bold;background-color:#cccbcb"


    },
    c2(){
      this.active2="text-align: center;font-size: 15px;color:white;font-weight: bold;background-image: linear-gradient(to right,limegreen ,darkgreen);"
      this.active4="text-align: center;font-size: 15px;color:black;font-weight: bold;background-color:#cccbcb"
      this.active1="text-align: center;font-size: 15px;color:black;font-weight: bold;background-color:#cccbcb"
      this.active3="text-align: center;font-size: 15px;color:black;font-weight: bold;background-color:#cccbcb"
      this.active5="text-align: center;font-size: 15px;color:black;font-weight: bold;background-color:#cccbcb"


    },



    c3(){
      this.active3="text-align: center;font-size: 15px;color:white;font-weight: bold;background-image: linear-gradient(to right,limegreen ,darkgreen);"
      this.active4="text-align: center;font-size: 15px;color:black;font-weight: bold;background-color:#cccbcb"
      this.active1="text-align: center;font-size: 15px;color:black;font-weight: bold;background-color:#cccbcb"
      this.active2="text-align: center;font-size: 15px;color:black;font-weight: bold;background-color:#cccbcb"
      this.active5="text-align: center;font-size: 15px;color:black;font-weight: bold;background-color:#cccbcb"


    },
    c4(){

        this.active4="text-align: center;font-size: 15px;color:white;font-weight: bold;background-image: linear-gradient(to right,limegreen ,darkgreen);"
        this.active3="text-align: center;font-size: 15px;color:black;font-weight: bold;background-color:#cccbcb"
        this.active1="text-align: center;font-size: 15px;color:black;font-weight: bold;background-color:#cccbcb"
        this.active2="text-align: center;font-size: 15px;color:black;font-weight: bold;background-color:#cccbcb"
        this.active5="text-align: center;font-size: 15px;color:black;font-weight: bold;background-color:#cccbcb"


    },

    c5(){

      this.active5="text-align: center;font-size: 15px;color:white;font-weight: bold;background-image: linear-gradient(to right,limegreen ,darkgreen);"
      this.active3="text-align: center;font-size: 15px;color:black;font-weight: bold;background-color:#cccbcb"
      this.active1="text-align: center;font-size: 15px;color:black;font-weight: bold;background-color:#cccbcb"
      this.active2="text-align: center;font-size: 15px;color:black;font-weight: bold;background-color:#cccbcb"
      this.active4="text-align: center;font-size: 15px;color:black;font-weight: bold;background-color:#cccbcb"

    },



   }
}
</script>

<style >

</style>