<template>
  <div style="padding: 10px;display: flex;flex-direction: row;">
<!--    功能区域-->

    <div style="display: flex;flex-direction: column;flex: 3">
      <div style="padding: 10px;">
        <h2>系统用户列表</h2>
      </div>

      <div style="display: flex;flex-direction: row;">
        <div style="padding: 10px;">
          <el-button  type="primary" @click="add">新增</el-button>
        </div>
        <!--    搜索区域-->
        <div style="margin: 10px 0">
          <el-input style="width: 200px" v-model="search" placeholder="请输入用户名" clearable></el-input>
          <el-button type="primary" style="margin-left: 5px" @click="load">查询</el-button>
        </div>
      </div>




    <!--    tableData注入表格数据,stripe:斑马纹,border:边框,tableData:存储表中数据，从后端数据库中获得-->
      <el-table
        :data="tableData"
        stripe
        border
        :cell-style="{borderColor:'darkgray'}"
        :header-cell-style="{borderColor:'darkgray',background:'rgb(180, 229, 239)'}"
        style="border:1px solid darkgray ;max-width: 700px">
  <!--    定义表头,sortable:日期排序-->
<!--      该prop需要和数据库中定义变量一一对应-->
<!--        <el-table-column prop="id" label="ID" sortable />-->
        <el-table-column prop="username" label="用户名"  />
<!--      <el-table-column prop="password" label="密码"  />-->

        <el-table-column prop="school" label="单位"  />
        <el-table-column prop="role" label="身份(是否为管理员)"  />
        <el-table-column fixed="right" label="Operations" width="120">
        <template #default="scope">
          <el-button  type="primary" size="small" style="width: 40px" @click="handleEdit(scope.row)">编辑</el-button>
<!--          确定删除的弹出框-->
          <el-popconfirm title="确定删除吗" @confirm="handleDelete(scope.row.id)">
            <template #reference>
              <el-button type="danger" style="width: 40px" size="small"  >删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

<!--    分页处理,数据可以绑定到data中,默认数据不用绑定直接定义-->
      <div style="margin:10px 0">
        <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[5,10,20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
  <!--新增的弹出显示-->
        <el-dialog
            v-model="dialogVisible"
            title="新增"
            width="30%"

        >
  <!--        弹出框中设置表单-->
          <el-form
              label-width="150px"
              :model="form"
          >
            <el-form-item label="用户名">
              <el-input v-model="form.username" style="width: 80%" />
            </el-form-item>
            <el-form-item label="学校">
              <el-input v-model="form.school" style="width: 80%" />
            </el-form-item>
            <el-form-item label="密码">
              <el-input v-model="form.password" style="width: 80%" />
            </el-form-item>

            <el-space fill>
              <el-alert type="info" show-icon :closable="false">
                <p>若不设置密码，初始密码为<span style="font-weight: bold;color: red">123456</span>
                </p>
              </el-alert>
              <el-form-item label="是否设置为管理员">
                <el-switch v-model="form.role" />
              </el-form-item>

            </el-space>
          </el-form>
          <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="save" >
            确定
          </el-button>
        </span>
          </template>
        </el-dialog>
      </div>

    </div>

<!--    分割线-->
    <el-divider direction="vertical"  border-style="dashed" style="height: 500px;color: #1254cb;margin-left: 10px;margin-right: 10px">
    </el-divider>


    <div style="display: flex;flex-direction: column;flex: 2">
      <div style="padding: 10px;margin-bottom: 25px;">
       <h2>注册用户审核</h2>
      </div>
      <!--    搜索区域-->




      <!--    tableData注入表格数据,stripe:斑马纹,border:边框,tableData:存储表中数据，从后端数据库中获得-->
      <el-table
          :data="regisTableData"
          stripe
          border
          :cell-style="{borderColor:'darkgray'}"
          :header-cell-style="{borderColor:'darkgray',background:'rgb(180, 229, 239)'}"
          style="border:1px solid darkgray ;max-width: 500px">
        <!--    定义表头,sortable:日期排序-->
        <!--      该prop需要和数据库中定义变量一一对应-->
        <!--        <el-table-column prop="id" label="ID" sortable />-->
        <el-table-column prop="username" label="用户名"  />
        <!--      <el-table-column prop="password" label="密码"  />-->

        <el-table-column prop="school" label="单位"  />

        <el-table-column fixed="right" label="Operations" width="120">
          <template #default="scope">
            <el-button  type="primary" size="small" style="width: 40px" @click="handleEdit(scope.row)">审核</el-button>
            <!--          确定删除的弹出框-->
            <el-popconfirm title="确定删除吗" @confirm="regishandleDelete(scope.row.id)">
              <template #reference>
                <el-button type="danger" style="width: 40px" size="small"  >删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <!--    分页处理,数据可以绑定到data中,默认数据不用绑定直接定义-->
      <div style="margin:10px 0">
        <el-pagination
            :current-page="regisPage"
            :page-size="regispageSize"
            :page-sizes="[5,10,20]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="registotal"
            @size-change="regishandleSizeChange"
            @current-change="regishandleCurrentChange"
        />
        <!--新增的弹出显示-->
        <el-dialog
            v-model="dialogVisible"
            title="新增"
            width="30%"

        >
          <!--        弹出框中设置表单-->
          <el-form
              label-width="150px"
              :model="form"
          >
            <el-form-item label="用户名">
              <el-input v-model="form.username" style="width: 80%" />
            </el-form-item>
            <el-form-item label="学校">
              <el-input v-model="form.school" style="width: 80%" />
            </el-form-item>
            <el-form-item label="密码">
              <el-input v-model="form.password" style="width: 80%" />
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="form.email" style="width: 80%" />
            </el-form-item>

            <el-space fill>
              <el-alert type="info" show-icon :closable="false">
                <p>若不设置密码，初始密码为<span style="font-weight: bold;color: red">123456</span>
                </p>
              </el-alert>
              <el-form-item label="是否设置为管理员">
                <el-switch v-model="form.role" />
              </el-form-item>

            </el-space>
          </el-form>
          <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="save" >
            确定
          </el-button>
        </span>
          </template>
        </el-dialog>
      </div>

    </div>
  </div>

</template>




<script >
//引入request
import request from "@/utils/request";

export default {
  name: 'user_manage',
  components: {

  },

  data(){
    return{

      form:{
        role:false
      },
      dialogVisible:false,
      total:0,
      currentPage:1,
      search:"",
      tableData :[],
      //以下变量是对用户注册审核表的
      registotal:0,
      regisPage:1,
      regisSearch:"",
      regisTableData:[]
    }
  },
  created() {
    this.load();
    this.Regisload();
  },
  methods:{
    load(){
      //这个是获取当前可以登录的用户列表
      request.get("/api/user",{
        params: {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          search: this.search
        }
      }).then(res =>{
        console.log(res);
        this.tableData=res.data.records//将后台数据赋值到前台数据进行渲染
        this.total = res.data.total
      })
    },

    Regisload(){
      //获取需要审核的列表
      request.get("/api/user/getRegisList",{
        params: {
          pageNum: this.regisPage,
          pageSize: this.regispageSize,
          search: this.regisSearch
        }
      }).then(res =>{
        console.log(res);
        this.regisTableData=res.data.records//将后台数据赋值到前台数据进行渲染
        this.registotal = res.data.total
      })
    },
    //用来打开弹窗
    add(){
        this.dialogVisible=true
        this.form={}//将表单清空
    },
    save(){
      if(this.form.id){//如果id能够找到，那么save表现为更新
        request.put("/api/user",this.form).then(res => {
          console.log(res)
          //如果进行更新成功，则弹出提示框
          if(res.code ==='0'){
            this.$message({
              type:"success",
              message:"更新成功"
            })
          } else {
            this.$message({
              type:"error",
              message:res.msg
            })
          }
          this.load()//刷新表格数据
          this.Regisload();
          this.dialogVisible=false//关闭弹窗
        })
      }else {//否则表现为新增
        //设置代理api
        request.post("/api/user",this.form).then(res => {
          console.log(res)
          console.log(this.form.operator)
          if(res.code ==='0'){
            this.$message({
              type:"success",
              message:"新增成功"
            })
          } else {
            this.$message({
              type:"error",
              message:res.msg
            })
          }
          this.load()//刷新表格数据
          this.dialogVisible=false//关闭弹窗
        })
      }

    },
    handleDelete(id){
      request.delete("/api/user/"+id).then(res => {
        if(res.code ==='0'){
          this.$message({
            type:"success",
            message:"删除成功"
          })
        } else {
          this.$message({
            type:"error",
            message:res.msg
          })
        }
        this.load();//重新加载表格数据
      })
    },

    regishandleDelete(id){
      request.delete("/api/user/"+id).then(res => {
        if(res.code ==='0'){
          this.$message({
            type:"success",
            message:"删除成功"
          })
        } else {
          this.$message({
            type:"error",
            message:res.msg
          })
        }
        this.Regisload();//重新加载表格数据
      })
    },



    handleEdit(row){
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogVisible = true
    },
    handleSizeChange(pageSize){//改变每页个数触发
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum){//改变当前页码触发
      this.currentPage=pageNum
      this.load()
    },
    regishandleSizeChange(pageSize){//改变每页个数触发
      this.regpageSize = pageSize
      this.load()
    },
    regishandleCurrentChange(pageNum){//改变当前页码触发
      this.regisPage=pageNum
      this.load()
    }
  },



}
</script>
