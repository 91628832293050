import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// ��Ĭ��Ӣ�ĸ�Ϊ����
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn'
//����css
import '@/assets/css/global.css'
//����element plus

import   ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// import bodyParser from "body-parser";

createApp(App).use(store).use(router).use(ElementPlus,{locale}).mount('#app')
// App.use(bodyParser({limit: '50mb'}))
const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
}
