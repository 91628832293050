<template >
  <div style="height:60px; line-height: 60px;display: flex;background-image: linear-gradient(to right,limegreen ,darkgreen);overflow: hidden">
    <div style="flex: 1;width: 300px;font-size:30px;text-align: center;line-height:60px;color: white">
      <img :src="imgUrl" style="height: 50px; line-height: 54px;margin-left:5px;margin-top:5px"/>
    </div>

    <div style="flex: 10;text-align: left;font-size:30px;color: white;font-family: kaiti">
     作物靶标害虫监测图像识别与计数
    </div>
    <div style="flex:1;text-align: center">


      <!--    定义头部的下拉菜单-->
    <el-dropdown style="padding-top: 5px;">
    <span  style="height:50px;font-size:15px;border:0;line-height: 40px;width:70px;text-align:center;color: white">
      {{ username }}
       <el-icon  style="color: ghostwhite">
        <ArrowDown />
      </el-icon>
    </span>
      <template #dropdown>
        <el-dropdown-menu>
<!--          <el-dropdown-item @click="$router.push('/')">个人信息</el-dropdown-item>-->
          <el-dropdown-item @click="$router.push('/')">退出系统</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
  </div>
</template>

<script>

import { ArrowDown } from '@element-plus/icons-vue'
export default {
  name: "Header",
  components: {ArrowDown},
  data(){
    return{
      //获取图片渲染
      imgUrl:require('../img/header.png'),
      //从登录界面获取用户名
      username:sessionStorage.getItem("user").replace(/\"/g, "")
    }
  }
}
</script>

<style scoped>

</style>