<template>
  <div>
<!--    可以是登录界面和后台界面-->
    <router-view />


  </div>

</template>

<style>

</style>
<script>
//按alt+enter键进行Header组件导入


export default {
  name:"App",

}
</script>