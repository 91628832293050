<!--������̨��ҳ�Ŀ�ܽ���-->
<template>
  <!--    ͷ��-->
  <Header/>
  <!--    ����-->
  <div style="display: flex">
    <!--      �����-->
    <Aside />
    <!--      ��������-->
    <router-view style="flex: 1"/>

  </div>

</template>

<script>
import Header from "@/components/Header";
import Aside from "@/components/Aside";


export default {
  name: "Layout",
  components:{
    Header,
    Aside
  },
  data(){
      return{

      }
  }
}

</script>

<style scoped>

</style>